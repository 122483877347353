<template lang="html">
  <h1>Thank You!</h1>
  <h2>Scan the QR code below on the claw machine for a free game.</h2>
  <!-- <h2>Scan de QR code hieronder op de grijpmachine voor een gratis spel.</h2> -->
  <h2>Good luck!</h2>
  <img
    :src="
      'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=' +
      getSavedDataId +
      '&choe=UTF-8'
    "
    title="Link to Google.com"
  />
</template>

<script>
export default {
  name: "QrCodeScreen",
  data: () => ({}),
  computed: {
    getSavedDataId: function () {
      let savedData = this.$store.getters.getSavedData;
      if (savedData !== null) {
        return savedData._id;
      }
      return false;
    },
  },
  methods: {
    setQuestionTwo(answer) {
      this.$store.commit("setQuestionTwo", answer);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #6bc1d0;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 90%;
  padding: 10px;
  font-size: 40px;
  border-radius: 0px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
