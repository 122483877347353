<template>
  <div class="vh-100 screen" style="text-align: center">
    <img
      :src="
        'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=' +
        getUniqueString +
        '&choe=UTF-8'
      "
      title="Link to Google.com"
    />
  </div>
</template>

<script>
export default {
  name: "ClawView",
  components: {},
  computed: {
    getUniqueString() {
      let len = 24;
      const hex = "0123456789ABCDEF";
      let output = "";
      for (let i = 0; i < len; ++i) {
        output += hex.charAt(Math.floor(Math.random() * hex.length));
      }
      return "pri" + output;
    },
  },
};
</script>

<style>
.screen {
  /* background: url("~@/assets/home_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%; */
  overflow: hidden; /* Hide scrollbars */
}
</style>
