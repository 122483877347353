<template lang="html">
  <h1 class="title">
    In which skill sets do you expect an increase in need in 2023?
  </h1>
  <div class="d-grid gap-2">
    <button
      v-for="answer in answers"
      v-bind:key="answer"
      type="button"
      class="btn btn-lg text-light"
      :class="[answerChecked(answer) ? 'btn-primary' : 'btn-secondary']"
      @click="addRemoveAnswer(answer)"
    >
      <i class="bi bi-square" v-if="!answerChecked(answer)"></i>
      <i class="bi bi-check-square" v-if="answerChecked(answer)"></i>
      {{ answer }}
    </button>
    <button
      type="button"
      class="btn btn-primary text-light btn-lg"
      @click="saveAndGetQrCode()"
    >
      <span v-if="checkedAnswers.length < 1">None</span>
      <span v-if="checkedAnswers.length > 0">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "questionFourScreen",
  data: () => ({
    answers: [
      "Engineering",
      "Project Management",
      "Project Services",
      "Procurement",
      "HSE",
      "QA/QC",
      "Construction Support",
      "Commissioning Support",
    ],
    checkedAnswers: [],
  }),
  computed: {},
  methods: {
    addRemoveAnswer(answer) {
      if (this.checkedAnswers.indexOf(answer) !== -1) {
        this.checkedAnswers.splice(this.checkedAnswers.indexOf(answer), 1);
      } else {
        this.checkedAnswers.push(answer);
      }
    },
    answerChecked(answer) {
      if (this.checkedAnswers.indexOf(answer) !== -1) {
        return true;
      }
      return false;
    },
    saveAndGetQrCode() {
      this.$store.commit("setQuestionFour", this.checkedAnswers);
      let data = {};
      data.first_name = this.$store.getters.getFirstName;
      data.last_name = this.$store.getters.getLastName;
      data.email = this.$store.getters.getEmail;
      data.question_1 = this.$store.getters.getQuestionOne;
      data.question_2 = this.$store.getters.getQuestionTwo;
      data.question_3 = this.$store.getters.getQuestionThree;
      data.question_4 = this.$store.getters.getQuestionFour;
      data.scanned = false;
      this.$store.dispatch("saveVisitor", data);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #6bc1d0;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 90%;
  padding: 10px;
  font-size: 40px;
  border-radius: 0px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
