<template lang="html">
  <h1 class="title">Have you ever worked with a staffing partner before?</h1>
  <div class="d-grid gap-2">
    <button
      type="button"
      class="btn btn-secondary text-light btn-lg"
      @click="setQuestionTwo(true)"
    >
      Yes
    </button>
    <button
      type="button"
      class="btn btn-secondary text-light btn-lg"
      @click="setQuestionTwo(false)"
    >
      No
    </button>
  </div>
</template>

<script>
export default {
  name: "QuestionTwoScreen",
  data: () => ({}),
  computed: {},
  methods: {
    setQuestionTwo(answer) {
      this.$store.commit("setQuestionTwo", answer);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #6bc1d0;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 90%;
  padding: 10px;
  font-size: 40px;
  border-radius: 0px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
