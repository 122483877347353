<template>
  <InactiveScreen v-if="!hasActiveGame" />
  <ActiveScreen v-if="hasActiveGame" />
</template>

<script>
import InactiveScreen from "@/components/InactiveScreen.vue";
import ActiveScreen from "@/components/ActiveScreen.vue";

export default {
  name: "ClawView",
  components: {
    InactiveScreen,
    ActiveScreen,
  },
  data: () => ({
    activePlay: false,
  }),
  computed: {
    hasActiveGame() {
      let activeGameDiff = this.$store.getters.getActiveGameDiff;
      if (activeGameDiff < 14) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.timer = setInterval(this.getActivePlay, 2000);
  },
  methods: {
    getActivePlay() {
      this.$store.dispatch("getActiveGame");
    },
    beforeUnmount() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style>
h1 {
  font-size: 120px;
  color: #fff;
  padding-bottom: 20px;
}
img {
  padding-bottom: 20px;
}
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.screen {
  /* background: url("~@/assets/home_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%; */
  overflow: hidden; /* Hide scrollbars */
}
.home-featured {
  background-color: #e5e5e5;
  /* background-image: linear-gradient(to top right, purple, violet, orange); */
  background: linear-gradient(#6bc1d0, #9e9ea0);
  background-size: 600% 600%;
  -webkit-animation: gradanimate 0.5s ease infinite;
  animation: gradanimate 10s ease infinite;
}
@-webkit-keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
</style>
